<template>
  <div class="auxiliary-tool">
    <el-popover trigger="hover" content="上传图片到阿里云，服务端返回上传路径">
      <el-button slot="reference" @click="uploadImgDialogVisible = true">上传图片</el-button>
    </el-popover>

    <UploadImgDialog v-model="uploadImgDialogVisible" />
  </div>
</template>

<script>
import UploadImgDialog from './compoents/upload-img-dialog.vue'
export default {
  name: 'AuxiliaryTool',
  components: { UploadImgDialog },
  data() {
    return {
      uploadImgDialogVisible: false
    }
  },
  computed: {},
  mounted() {},
  methods: {}
}
</script>
