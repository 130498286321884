<template>
  <el-dialog
    title="上传图片"
    :visible.sync="dialogVisible"
    width="40%"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <ImagesUpload
      :action="API.uploadImage"
      :size="50"
      :width="550"
      :headers="{ token: getToken() }"
      @on-success="onSuccess"
    />
    <el-form v-if="res.data" label-suffix="：">
      <el-row>
        <el-form-item label="图片路径">
          <el-col :span="20">
            <el-input style="width: 100%;" :value="res.data" />
          </el-col>
        </el-form-item>
      </el-row>
    </el-form>

    <!-- <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span> -->
  </el-dialog>
</template>

<script>
import API from '@/apis/api-types.js'
import { getToken } from '@/utils/auth.js'
export default {
  name: 'UploadImgDialog',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      API,
      res: '',
      getToken
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onSuccess(res) {
      this.res = res
      if (res.code === 0) {
        this.$message.success('上传成功')
      }
    }
  }
}
</script>
